<template>
	<div class="mycard-select-card">
		<div class="card-title">
			{{customer.name}}
		</div>
		<div v-if="$notEmpty(customer.nick_name)" class="text-muted">
			{{customer.nick_name}}
		</div>
	</div>
</template>

<script>
export default {
	props : {
		customer : {
			type : null,
			default : () => []
		} ,
	}
}
</script>
