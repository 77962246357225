<template>
	<MySelect
		v-bind="$attrs"
		class="customer-select"
		:loading="searchLoading"
		:pagination="pagination"
		option-label-prop="label"
		:value="sValue"
		@change="handleChange"
		@clear-search="clearSearchCustomer"
		@search="searchCustomer">
		<a-select-option v-for="customer in customers" :key="customer.id"
			:value="customer.id"
			:label="customer.name">
			<CustomerSelectCard
				:customer="customer"/>
		</a-select-option>
	</MySelect>
</template>

<script>
import MySelect from "@components/input/MySelect.vue"
import CustomerSelectCard from "./CustomerSelectCard.vue"
import {Select} from "ant-design-vue"
import axios from "axios"
import _get from "lodash/get"
import ApiError from "@utils/errors/ApiError"
export default {
	components : {
		MySelect,
		"a-select-option" : Select.Option, CustomerSelectCard
	} ,
	model: {
    prop: 'value',
    event: 'change'
	},
	props : {
		selectMode : {
			type: null,
			default : undefined
		} ,
		value : {
			type : null,
			default : undefined
		} ,
	} ,
	data(){
		return {
			searchLoading : false ,
			customers : [] ,
			pagination : {
				pageSize : 8 ,
				current : 1,
				total : 0,
			}
		}
	} ,
	computed: {
		sValue() {
			return _get(this.value,'id')
		}
	} ,
	methods : {
		clearSearchCustomer() {
			this.pagination.current = 1;
			this.pagination.total = 0;
			this.customers=[]
		} ,
		handleChange(value) {
			let selectedCustomer;
			if (value) {
				selectedCustomer = {...this.customers.find((customer) => customer.id==value)}
			} else {
				selectedCustomer = {}
			}
			this.$emit('change',selectedCustomer)
		} ,
		searchCustomer(searchEvent, immediateAssign=false) {
			this.searchLoading = true
			const searchParamsData = {
				search : {
					page_size : this.pagination.pageSize ,
					current_page : searchEvent.page,
				} ,
				filter : {
					keyword : searchEvent.search ,
				}
			}
			 axios.get("/api/customers/search",{params : searchParamsData}).then((response)=>{
				const page = response.data.data.pagination;
				this.customers = response.data.data.customers;
				if(immediateAssign) {
					this.handleChange(this.customers[0].id);
				}

			 	this.pagination.current = searchEvent.page;
			 	this.pagination.total = page.total;
			 }).catch((error)=>{
			 	this.$message.error(ApiError.getDisplayErrorMessage(this,error));
			 }).finally(()=>{
			 	this.searchLoading = false
			 })
		}
	}
}
</script>
<style lang="less" scoped>
.customer-select {
	width : 100%;
	min-width : 150px;
	max-width : 250px;
}
</style>
