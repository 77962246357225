<template>
	<ChartContainer
		:loading="false"
		:is-empty="totalData <= 0"
		:error-message="null"
		:custom-legend="true"
		:container-width="500"
		v-bind="$attrs">
		<VerticalBarChart :hide-legend="true" :data="chartData" :chart-options="chartOptions" :clickable="true" v-on="$listeners" />
		<span slot="title">
			{{ name }}
		</span>
	</ChartContainer>
</template>


<script>
import ChartContainer from './general/ChartContainer.vue'
import VerticalBarChart from './general/VerticalBarChart.vue'

export default {
	components: {
		ChartContainer,
		VerticalBarChart,
	},
	props: {
		name: {
			type: String,
			required: true,
		},
		chartData: {
			type: Object,
			required: true,
		},
		chartOptions: {
			type: Object,
			required: false,
			default: () => {},
		},
		totalData: {
			type: Number,
			required: true,
		},
	},
}
</script>
