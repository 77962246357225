<script>
import {Bar} from "vue-chartjs"
export default {
	extends : Bar ,
	props: {
		width: {
			type: Number,
			default: undefined
		},
		height: {
			type: Number,
			default: undefined,
		},
		hideLegend: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			required: true,
			default: () => {}
		},
		chartOptions: {
			type: Object,
			required: false,
			default: () => {}
		},
		clickable: {
			type: Boolean,
			require: false,
			default: false
		}
	},
	data(){
		return {
			currentSelectedIndex: null
		}
	},
	computed:{
		maxValue() {
			try {
				const datasets = this.data.datasets;
				const dataArray = datasets.map(dataset=>dataset.data);
				const datasetLengths = dataArray.map(dataset=>dataset.length);
				const maxDataLength = Math.max(...datasetLengths);

				const result = Array.from({length:maxDataLength});
				const sumArray = result.map((_, i) => Math.max(...dataArray.map(eData => eData[i] || 0)));
				return Math.ceil(Math.max(...sumArray) * 1.1)
			}
			catch (e) {
				return undefined;
			}
		}
	},
	watch:{
		data: {
			handler(){
				this.applyChartData(this.data, this.chartOptions);
			}
		}
	},
	mounted(){
		this.applyChartData(this.data, this.chartOptions);
	},
	methods: {
		chartData(hightlightIndex=null){
			try {
				const newData = {...this.data};
				const datasets = this.data.datasets
				const newDataset = datasets.map(dataset=>{
					const datasetColor = dataset.backgroundColor;
					return {
						... dataset,
						backgroundColor: dataset.data.map((data,index)=>{
							if(hightlightIndex !== null) {
								return hightlightIndex === index ? datasetColor : `${datasetColor}66`
							} else {
								return datasetColor
							}
						})
					}
				})
				newData.datasets = newDataset;
				return newData;
			}
			catch (e){
				return this.data;
			}
		},
		applyChartData(chartData=null,chartOptions={}) {
			if (chartData) {
				this.renderChart(chartData,{
					responsive : true,
					legend : {position : 'bottom', display : !this.hideLegend},
					animation: {
						animateScale: true,
						animateRotate: true
					} ,
					scales: {
        				yAxes: [{
            				ticks: {min: 0, precision : 0, suggestedMax:this.maxValue}
        				}],
    				},
					onClick: (e, activeEls) => {
						if(this.clickable) {
							if (activeEls.length > 0) {
								const segment = activeEls[0]
								const selectedIndex = segment._index;
								const selectedValue = this.data.labels[selectedIndex];

								if(selectedIndex != this.currentSelectedIndex){
									this.currentSelectedIndex = selectedIndex;
									this.$emit('clicked',selectedValue)
								}
								else{ 
									this.currentSelectedIndex = null;
									this.$emit('clicked', undefined)
								}
								this.applyChartData(this.chartData(this.currentSelectedIndex), {
									...this.chartOptions,
									animation: {
										duration: 0
									}
								});
							}
						}
					},
					...chartOptions
				})
			}
		}
	}
}
</script>
