<script>
import {Pie} from "vue-chartjs"
export default {
	extends : Pie,
	props : {
		width : {
			type : Number,
			default : undefined
		},
		height : {
			type : Number,
			default : undefined,
		},
		hideLegend : {
			type : Boolean,
			default : false,
		},
		clickable : {
			type : Boolean,
			default : false,
		},
		chartDataset : {
			type: Object,
			required : true,
		},
		options : {
			type: Object,
			default : () => {}
		}
	},
	data () {
		return {
			isSliceClicked: false,
			currentSliceIndex: undefined,
			currentSegment: undefined
		}
	},
	watch : {
		chartDataset : {
			handler() {
				this.applyChartData(this.chartDataset, this.options)
			}
		}
	},
	mounted() {
		this.applyChartData(this.chartDataset, this.options)

	},
	methods: {
		applyChartData(chartData=null, chartOptions={}) {
			this.renderChart(chartData, {
				responsive : true,
				legend : {position : 'bottom', display : !this.hideLegend},
				animation: {
					animateScale: true,
					animateRotate: true
				},
				onClick: (e, activeEls) => {
					if (this.clickable && activeEls.length > 0) {
						const labels = chartData.labels
						const segment = activeEls[0]
						const clickedSlice = labels[segment._index]
						if (!this.isSliceClicked) {
							segment._model.outerRadius += 10;
							this.isSliceClicked = true
							this.currentSliceIndex = segment._index
							this.currentSegment = segment
							this.$emit('clicked', clickedSlice)
						} else if (this.isSliceClicked && this.currentSliceIndex === segment._index) {
							segment._model.outerRadius -= 10
							this.isSliceClicked = false
							this.currentSliceIndex = undefined
							this.currentSegment = undefined
							this.$emit('clicked', undefined)
						} else {
							this.currentSegment._model.outerRadius -= 10
							segment._model.outerRadius += 10
							this.currentSliceIndex = segment._index
							this.currentSegment = segment
							this.$emit('clicked', clickedSlice)
						}
					}
				},
				...chartOptions
			})
		},
		resetChart() {
			this.applyChartData(this.chartDataset, this.options)
			this.isSliceClicked = false
			this.currentSliceIndex = undefined
			this.currentSegment = undefined
		}
	}
}
</script>
