<template>
	<component :is="type" :class="cssClasses" @click="onClick">
		<span>
			<slot>
				{{title}}
			</slot>
		</span>
		<a-icon type="right" class="expandable-icon"/>
	</component>
</template>

<script>
export default {
	model : {
		prop : 'value' ,
		event : 'change'
	} ,
	props : {
		value : {
			type : Boolean ,
			default : false
		} ,
		title : {
			type : String ,
			default : "Click Me"
		} ,
		buttonType : {
			type: Boolean,
			default : false,
		}
	} ,
	computed : {
		type() {
			return this.buttonType ? "button" : "div";
		} ,
		cssClasses() {
			return [
				'expandable' ,
				{
					'expandable-open' : this.value ,
					'ant-btn' : this.buttonType,
				}
			]
		}
	} ,
	methods : {
		onClick() {
			this.$emit('change',!this.value)
		}
	}
}
</script>

<style lang="less">
.expandable {
	display : inline-block;
	div& {
		.text-emphasis-variant(@info-color);
		text-decoration: underline;
		text-decoration-style: dashed;
	}
	button& {
		text-decoration : none;
	}

	cursor: pointer;
	user-select : none;
	.expandable-icon {
		content : '>';
		transition: transform .3s;
	}
	&.expandable-open {
		.expandable-icon {
			transform: rotate(90deg);
		}
	}
}
</style>
